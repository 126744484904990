import * as Sentry from '@sentry/nextjs';
import { isAnalyticsEnabled } from './index';

const FORCE_FACEBOOK_CAPTURE = true;

export const facebookTrackEvent = (eventName: string, eventParams: Record<string, any>) => {
  if (typeof window === 'undefined') return;

  try {
    if (FORCE_FACEBOOK_CAPTURE || isAnalyticsEnabled()) {
      // @ts-ignore
      window.fbq('track', eventName, eventParams);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const facebookTrackCompleteRegistration = (eventParams: Record<string, any>) => {
  facebookTrackEvent('CompleteRegistration', eventParams);
};
